import { CompanyInvoice } from "@api/graphql/types";
import { Box } from "@material-ui/core";
import { transformToCurrency } from "@utils/transform";
import React from "react";

import { InfoCard } from "~/components/InfoCard";
import { ITableColumn, ITableRow, Table } from "~/components/Table";
import { Typography } from "~/components/Typography";

import Confirmed from "~/assets/vectors/tick.svg";
import Pending from "~/assets/vectors/alert-triangle-orange.svg";

const getInvoiceStatus = (status: string) => {
	if (status === "paid") {
		return (
			<Box
				style={{
					display: "flex",
					gap: 8,
					alignItems: "center",
				}}
			>
				<Typography>Paid</Typography>
				<Confirmed />
			</Box>
		);
	}
	return (
		<Box
			style={{
				display: "flex",
				gap: 8,
				alignItems: "center",
			}}
		>
			<Typography>Unpaid</Typography>
			<Pending />
		</Box>
	);
};

export const invoicesTable: ITableColumn[] = [
	{
		label: "Recipient",
		property: "company_name",
		width: "auto",
	},
	{
		label: "Number",
		property: "invoice_number",
		width: "auto",
	},
	{
		label: "Date",
		property: "date",
		width: "auto",
	},
	{
		label: "Due Date",
		property: "due_date",
		width: "auto",
	},
	{
		label: "Amount",
		property: "total",
		width: "auto",
		cell: (value): string => transformToCurrency(value),
	},
	{
		label: "Status",
		property: "status",
		width: "auto",
		cell: (value) => (value ? getInvoiceStatus(value) : null),
	},
	{
		label: "Download",
		property: "invoice_url",
		width: "auto",
		cell: (value) => (
			<a
				style={{ cursor: "pointer" }}
				href={value}
				target="_blank"
				rel="noopener noreferrer"
			>
				View
			</a>
		),
	},
];

const InvoicesTable = ({
	loading,
	data,
}: {
	loading: boolean;
	data: CompanyInvoice[];
}) => {
	const rows: ITableRow[] =
		data?.map((node: any) => {
			return {
				id: node?.invoice_id,
				values: [
					node?.company_name,
					node?.invoice_number,
					node?.date,
					node?.due_date,
					node?.total,
					node?.status,
					node?.invoice_url,
				],
			} as ITableRow;
		}) || [];

	return (
		<InfoCard loading={loading}>
			<Table
				columns={invoicesTable}
				rows={rows || []}
				loading={loading}
			/>
		</InfoCard>
	);
};

export default InvoicesTable;
